$transition-settings: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

@mixin label($state: null) {
  @if $state == focused {
    @apply text-primary;

    max-width: 125%;
    transform: translate(0, 0) scale(1);
    clip: auto;
    clip-path: none;

    @screen md {
      transform: translate(0, 0) scale(0.75);
    }
  } @else if $state == error {
    @apply text-error;
  } @else if $state == disabled {
    @apply text-disabled;
  } @else if $state == helper {
    @apply text-ellipsis whitespace-nowrap overflow-hidden absolute max-w-full block;

    margin-top: 2px;
    font-size: 12px;
  } @else {
    @apply text-ellipsis whitespace-nowrap overflow-hidden absolute max-w-full block text-placeholder;

    left: 0;
    top: 0;
    transform-origin: left top;
    transform: translate(0, 16px) scale(1);
    transition:
      transform $transition-settings,
      max-width $transition-settings;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
  }
}

@mixin input($state: null) {
  @if $state == focused {
    @apply border-secondary;
  } @else if $state == error {
    @apply border-error;
  } @else if $state == without-label {
    @apply pt-4;
  } @else {
    /** to remove iOS default border radius on input elements */
    -webkit-border-radius: 0;

    @apply appearance-none border-0 border-b border-outline-primary px-0 bg-transparent w-full text-primary;

    padding-bottom: 4px;

    &:focus {
      @apply outline-none shadow-none;
    }

    &:disabled {
      @apply text-disabled cursor-not-allowed;
    }
  }
}
