.wrapper {
  @apply text-center mx-auto;

  max-width: 1000px;

  & + .featureWrapper {
    @apply mt-9 lg:mt-10;
  }
}

.title {
  @apply text-black mb-2;

  font-size: 28px;

  @screen lg {
    font-size: 36px;
  }
}

.featureWrapper {
  // this is to allow columns auto-fill when less than 4 items on big screens
  // if more than 4 - render 4 per row with vertical gap
  --grid-max-columns: 1;
  --grid-gap-x: 1rem;
  --grid-gap-y: 2rem;

  @screen md {
    --grid-max-columns: 2;
    --grid-gap-x: 2rem;
    --grid-gap-y: 2.5rem;
  }

  @screen lg {
    --grid-max-columns: 4;
  }

  // calculate columns width
  --max-column-width: 100% / var(--grid-max-columns, infinity) - var(--grid-gap-x);
  --column-width: max(var(--max-column-width), min(10rem, 100%));

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(var(--column-width), 1fr));
  gap: var(--grid-gap-y, 2rem) var(--grid-gap-x, 1rem);
}

.icon {
  @apply mx-auto lg:mb-5 mb-3;
}

.description {
  @apply text-secondary-2 leading-normal mt-2;
}
