.wrap {
  background-color: #f0f0f0;

  @screen sm {
    @apply bg-white;
  }
}

.header {
  font-size: 28px;
  line-height: 1.1;

  @screen md {
    font-size: 36px;
  }

  @screen lg {
    font-size: 40px;
  }
}

.description {
  font-size: 18px;
  line-height: 1.15;

  @screen md {
    font-size: 22px;
  }

  @screen lg {
    font-size: 24px;
  }
}

.itemDescription {
  p {
    min-height: 20px;
  }
}

.cta {
  @apply w-full lg:w-auto font-bold h-auto py-3 lg:px-5 mt-6;

  font-size: 18px;
  line-height: 27px;
}

.textContainer {
  min-height: 550px;

  @media (width <= 768px) {
    min-height: 385px;
  }
}

.mediaContainer {
  @apply rounded-2xl overflow-hidden;

  height: 358px;
  width: 358px;

  @screen sm {
    height: 412px;
    width: 412px;
  }

  @screen lg {
    height: 586px;
    width: 586px;
  }
}

.collapse {
  background-color: #f0f0f0;

  @screen sm {
    @apply bg-white;
  }

  &.active {
    @apply bg-white;

    @screen sm {
      background-color: #f0f0f0;
    }
  }
}
