@use '@assets/mixins/typography';

.subheader {
  @screen md {
    @include typography.text('header-2');
  }
}

.icon {
  max-width: 36px;
  max-height: 36px;
}
