@use 'sass:math';

@mixin arrow-button($dir: text, $indent: 0) {
  @apply absolute rounded-full w-xxl h-xxl bg-white z-20;

  top: 50%;
  transform: translateY(-50%);
  #{$dir}: $indent;
  -webkit-tap-highlight-color: transparent;

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  & > svg {
    transform: scale(1.15);
    margin: 0 auto;
  }
}

@function get-width($num) {
  @return math.div((100% - (($num - 1) * 1%)), $num);
}

.canvas {
  @apply w-full overflow-hidden relative;
}

.arrowLeft {
  @include arrow-button(left);

  left: -6px;
}

.arrowRight {
  @include arrow-button(right);

  right: -6px;
}

.noPadding {
  padding: 0 !important;
}

.arrowOutside {
  .arrowLeft {
    left: -60px;
  }

  .arrowRight {
    right: -60px;
  }

  @media screen and (width <= 1360px) {
    .arrowLeft {
      @apply left-3 bg-white;
    }

    .arrowRight {
      @apply right-3 bg-white;
    }
  }
}

.items {
  @apply flex items-start w-full overflow-scroll overflow-y-hidden;

  padding-bottom: 30px;
  margin-bottom: -30px;
  scroll-behavior: smooth;
  scroll-snap-type: inline mandatory;
  flex-flow: row nowrap;

  & > * {
    display: block;

    // need min and max to avoid weird sizing issues
    min-width: 100%;
    max-width: 100%;
    width: 100%;

    & {
      scroll-snap-align: start;
    }

    &:last-child {
      scroll-snap-align: end;
      margin-right: 0;
    }
  }
}

.twoItems > * {
  min-width: get-width(2);
  max-width: get-width(2);
  width: get-width(2);
  margin-right: 1%;
}

.threeItems > * {
  min-width: get-width(3);
  max-width: get-width(3);
  width: get-width(3);
  margin-right: 1%;
}

.fourItems > * {
  min-width: get-width(4);
  max-width: get-width(4);
  width: get-width(4);
  margin-right: 1%;
}

.fiveItems > * {
  min-width: get-width(5);
  max-width: get-width(5);
  width: get-width(5);
  margin-right: 1%;
}

.thumbnails {
  @apply grid;
  grid: {
    auto-columns: 1fr;
    column-gap: 7px;
    auto-flow: column;
  }

  @screen lg {
    @apply mx-auto;

    width: fit-content;
    max-width: 100%;
  }
}

.thumbnail {
  @apply relative leading-none w-full pt-s pb-m;

  height: 5px;
  font-size: 0;

  @screen lg {
    width: 85px;
  }

  > span {
    @apply block absolute w-full bg-outline-primary;

    bottom: 50%;
    left: 0;
    height: 2px;
  }
}

.selectedThumbnail {
  > span {
    @apply bg-cta;

    height: 4px;
  }
}

.thumbnailExceeded {
  @screen lg {
    @apply w-full;

    .thumbnail {
      width: 100%;
    }
  }
}
