.item {
  @apply p-4 border-b border-outline-primary;
}

.label {
  @apply flex items-start cursor-pointer;
}

.radio {
  @apply mr-2 h-5 w-5 cursor-pointer;

  appearance: none;
  background-color: #fff;
  background-origin: border-box;
  border: 1px solid var(--grey-600) !important;
  border-radius: 100%;
  color: var(--eggplant);
  display: inline-block;
  flex-shrink: 0;
  print-color-adjust: exact;
  vertical-align: middle;
  user-select: none;

  &:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
    background-color: currentColor;
    background-size: 100% 100%;
    background-position: 50%;
    background-repeat: no-repeat;
    border-color: var(--eggplant);
  }
}

.hideRadio {
  display: none !important;
}

.active {
  @apply bg-grey-100;
}
