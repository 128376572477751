@use '@assets/mixins/contentful-text-option' as option;

.container {
  background-color: var(--white);
  text-align: center;
}

.cardContainer {
  @apply bg-light-gray p-6 relative mb-8;

  min-width: 240px;
  background-color: var(--white);
  border-radius: 32px;
}

.spec {
  @apply text-left p-4;

  background-color: var(--gray-5);
}

.spec:nth-child(odd) {
  background-color: white;
}

.media {
  @apply mx-auto mt-5 mb-4;

  width: 100%;
  max-width: 664px;
}

.icon {
  @apply flex w-fit items-center mx-auto mb-3 justify-center;

  width: 100%;
  height: 65px;
  align-items: center;
}

.subheader {
  @include option.text-option(true);

  line-height: 1 !important;
}

.description {
  max-width: 850px;

  @apply mx-auto;
}

@media (width <= 640px) {
  .subheader {
    @apply mt-6;
  }
}

.card {
  @screen md {
    width: calc(50% - 2rem);
  }

  @screen lg {
    width: calc(25% - 0.75rem);
  }
}

.couponContainer {
  border: 2px dashed var(--purple);
  border-radius: 14px;
  max-width: 100%;
}
