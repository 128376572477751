.eyebrow {
  @apply text-center pb-4;

  letter-spacing: 0.04em;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: var(--purple);
}

.title {
  @apply text-center pb-8;

  letter-spacing: 0;
  color: var(--black);
}

.subtitle {
  @apply text-center pb-8;

  letter-spacing: 0;
  color: var(--pitch-black);
}

.caseTitle {
  @apply text-left font-bold mt-2;

  letter-spacing: 0;
  line-height: 26.6px;
  color: var(--black);
}

.caseDescription {
  @apply text-left;

  font-weight: 400;
  letter-spacing: 0;
  color: var(--gray-50);
}

.useCaseItem {
  a {
    &:hover {
      img {
        @apply scale-105;
      }

      .caseTitle {
        color: var(--purple);
      }
    }
  }
}
