.block {
  padding-top: 30px;
  padding-bottom: 30px;

  @screen md {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @screen lg {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    padding-bottom: 60px;

    @screen md {
      padding-bottom: 80px;
    }

    @screen lg {
      padding-bottom: 100px;
    }
  }

  // this is to reset spacing if module called inside of .Section_block
  // ie used as an internal part of other module
  .block {
    &:first-of-type {
      padding-top: unset;
    }

    &:last-of-type {
      padding-bottom: unset;
    }
  }
}
