.planName {
  @apply text-secondary-2 text-center  md:mb-6 font-bold;
}

.featureName {
  @apply text-secondary-2 font-bold pt-4 pb-3;

  min-width: 125px;
}

div.tooltipContent {
  @apply bg-accents-10;

  max-width: 254px;
}

div.arrowClassName {
  &::before {
    border-bottom-color: transparent !important;
    border-top-color: transparent !important;
  }

  &::after {
    border-color: transparent var(--accents-10) transparent transparent !important;
  }
}

%cell {
  @apply flex items-center justify-center;
}

.cell {
  @extend %cell;
}

.borderedCell {
  @apply border border-outline-primary;
}

.footerCell {
  @extend %cell;
  @apply hidden;

  @screen md {
    @apply flex;
  }
}

.mobileFooter {
  @apply pt-4 px-2 block;

  @screen md {
    @apply hidden;
  }
}

.button {
  @apply overflow-hidden py-1.5;

  width: 10rem;
  min-width: fit-content;
  max-width: 100%;
  text-wrap: wrap;
  height: auto !important;
}
