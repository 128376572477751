@use '@assets/mixins/darken' as dark;
@use '@assets/mixins/contentful-text-option' as option;

.root {
  @apply relative h-[500px] md:flex md:h-[430px];

  &.imageLeft {
    @apply md:flex-row;
  }

  &.imageRight {
    @apply md:flex-row-reverse;
  }

  /* This module is a bit wider than our standard module
   @screen md {
     max-width: 1280px;
   } */
}

.contentRoot {
  @apply absolute inset-0 z-10;

  @screen md {
    @apply static w-1/2 flex flex-row items-center;

    &.right {
      @apply justify-end;

      .content {
        @apply mr-11 flex flex-wrap justify-between;
      }
    }

    &.left {
      @apply justify-start;

      .content {
        @apply ml-7;
      }
    }
  }
}

.content {
  @apply h-full p-8 flex flex-col justify-between text-white;

  .headline {
    @apply mb-4;
    @include option.text-option(true);

    @screen lg {
      @apply text-[50.4px] leading-[60.48px];
    }
  }

  .description {
    @apply font-bold text-[21.28px] leading-[26.6px] mb-5 text-inherit;
    @apply md:mb-9 md:max-w-md;
  }

  @screen md {
    @apply block max-w-lg h-auto;
  }

  @screen xl {
    @apply p-0;
  }
}

.image {
  @apply object-cover w-full aspect-[375/500] h-[500px];
  @include dark.darken;

  @screen md {
    @apply aspect-square h-[580px];

    filter: unset;
  }
}

.cta {
  @apply w-full lg:w-auto font-bold h-auto py-3 lg:px-5;

  font-size: 24px;
  line-height: 26.4px;
}

/*
  HOMEPAGE THEMES
*/
.default {
  background-color: var(--eggplant);

  .cta {
    background-color: var(--purple);
  }
}

.commercialspot {
  background-color: var(--pitch-black);

  .cta {
    background-color: var(--purple);
  }
}

.earlyblackfriday {
  background-color: var(--eggplant);

  .cta {
    background-color: var(--heliotrope);
    color: var(--mirage);

    &:hover {
      background-color: var(--heliotrope);
      color: var(--mirage);
    }
  }
}

.earlyblackfridayv2 {
  background-color: var(--purple);

  .cta {
    background-color: var(--mirage);
    color: var(--white);

    &:hover {
      background-color: var(--mirage);
      color: var(--heliotrope);
    }
  }
}

.blackfriday {
  background-color: var(--mirage);

  .cta {
    background-color: var(--violet);
    color: var(--mirage);

    &:hover {
      background-color: var(--violet);
      color: var(--black);
    }
  }
}

.peakholidayv1 {
  background-color: var(--moss-grey);

  .cta {
    background-color: var(--electric-green);
    color: var(--moss-grey);

    &:hover {
      background-color: var(--electric-green);
      color: var(--moss-grey);
    }
  }
}

.peakholidayv2 {
  background-color: var(--ceramic-blue);

  .content {
    color: var(--white);

    @screen md {
      color: var(--moss-grey);
    }
  }

  .cta {
    background-color: var(--pepper);
    color: var(--white);

    &:hover {
      background-color: var(--pepper);
      color: var(--white);
    }
  }
}

.earlyblackfridayv3 {
  background-color: var(--white);

  .content {
    color: var(--white);

    @screen md {
      color: var(--mirage);
    }
  }

  .headline {
    color: var(--white);

    @screen md {
      color: var(--purple);
    }
  }

  .cta {
    background-color: var(--purple);
    color: var(--white);

    &:hover {
      background-color: var(--purple);
      color: var(--black);
    }
  }
}

/* Evergreen V1 H1 2024 */
.evergreenv1 {
  background-color: var(--mint);

  .content,
  .headline {
    color: var(--black);
  }

  .cta {
    background-color: var(--white);
    color: var(--purple);

    &:hover {
      background-color: var(--white);
      color: var(--eggplant);
    }
  }
}

/* Evergreen V2 H1 2024 */
.evergreenv2 {
  background-color: var(--sky);

  .content,
  .headline {
    color: var(--black);
  }

  .cta {
    background-color: var(--purple);
    color: var(--white);

    &:hover {
      background-color: var(--dark-lilac);
      color: var(--white);
    }
  }
}

/* Evergreen V3 H1 2024 */
.evergreenv3 {
  background-color: var(--caribbean);

  .content {
    color: var(--white);
  }

  .cta {
    background-color: var(--yellow-50);
    color: var(--black);

    &:hover {
      background-color: var(--yellow-50);
      color: var(--black);
    }
  }
}

/* Evergreen V4 H1 2024 */
.evergreenv4 {
  background-color: var(--eggplant);

  .content {
    color: var(--white);
  }

  .cta {
    background-color: var(--mint);
    color: var(--black);

    &:hover {
      background-color: var(--green-50);
      color: var(--white);
    }
  }
}

/* Evergreen V5 H1 2024 */
.evergreenv5 {
  background-color: var(--eggplant);

  .content {
    color: var(--white);
  }

  .cta {
    background-color: var(--sky);
    color: var(--black);

    &:hover {
      background-color: var(--sky);
      color: var(--black);
    }
  }
}
