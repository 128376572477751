@use '@assets/mixins/typography';

.wrapper {
  @apply flex flex-col lg:flex-row mx-auto w-full;

  max-width: 600px;

  @screen lg {
    max-width: unset;
    height: 330px;
  }

  picture {
    @apply shrink-0 lg:h-full;
  }
}

.image {
  @apply hidden lg:block h-full object-cover;

  aspect-ratio: 260/330;

  &.doubleImage {
    @apply w-auto;
  }

  &Mobile {
    @apply w-full block lg:hidden;

    &.doubleImage {
      aspect-ratio: 343/128;
    }
  }
}

// to target GIF image container
.imageContainer {
  display: none !important;
  aspect-ratio: 260/330;

  @screen lg {
    display: block !important;
  }
}

.contentWrapper {
  @apply px-4 py-8 flex flex-col items-center justify-center bg-transparent grow;

  @screen lg {
    @apply p-4;

    &.single {
      @apply flex-row justify-between px-8 gap-x-7;

      .textContent {
        @apply items-start;
      }

      .eyebrow,
      .headline,
      .body {
        @apply text-left;
      }

      .cta {
        margin-top: 0;
      }
    }
  }

  .textContent {
    @apply flex flex-col items-center justify-center;
  }

  .headline {
    @screen lg {
      @include typography.text('header-3');
    }
  }

  .cta {
    @apply mt-4;

    position: relative;
    padding-inline: 2rem;

    svg {
      position: absolute;
      width: 10px;
      right: 14px;
      opacity: 0;
      transition: all 0.2s linear 0s;
    }

    &:hover {
      svg {
        opacity: 1;
      }
    }
  }
}
