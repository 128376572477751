.root {
  @apply box-content;

  width: 218px;
  height: 150px;

  @media screen and (width >= 768px) {
    width: 245px;
    height: 222px;
  }

  .imageWrapper {
    @apply relative mb-4;

    padding-top: 60px;

    @media screen and (width >= 768px) {
      padding-top: 100px;
    }
  }

  .description {
    @apply flex items-center mb-3 justify-center text-secondary-2 md:text-secondary-2 text-sm md:text-base;

    @media screen and (width >= 768px) {
      height: 54px;
    }
  }
}
