.root {
  .contentRoot {
    @apply px-3 py-3 md:pt-5 flex flex-col justify-between h-full;
  }

  .name {
    background-color: rgba(white, 0.6);
  }

  .description {
    @apply leading-4;
  }

  .whiteName {
    @apply text-white;

    background-color: rgba(black, 0.6);
  }

  .name,
  .whiteName {
    @apply leading-4;

    padding: 7px;
    border-radius: 7px;
  }

  .itemImage {
    object-position: center center;

    @apply w-full h-full object-cover;

    &.mobileImage {
      @apply md:hidden;
    }

    &:not(.mobileImage) {
      @apply hidden md:block;
    }
  }

  .flickImage {
    @apply invisible absolute inset-0;
  }

  &:hover {
    .mainImage {
      @apply invisible;
    }

    .flickImage {
      @apply visible;
    }
  }
}
