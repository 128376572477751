.headline {
  @apply text-cta capitalize;
}

.title {
  @apply text-black mb-2;
}

.description {
  @apply text-secondary-2 leading-normal mb-5;

  @screen lg {
    @apply mb-4;
  }
}

.hoverOverlay {
  transition: background-color 0.25s ease-in-out;

  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
}
