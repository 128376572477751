.root {
  @apply relative h-[500px] md:h-[430px];
}

.mobileImage {
  @apply hidden;

  @media screen and (width <= 767px) {
    @apply block object-cover w-full h-[500px];
  }
}

.tabletImage {
  @apply hidden;

  @media screen and (min-width: theme('screens.md')) and (max-width: theme('screens.lg')) {
    @apply block object-cover w-full h-[430px];
  }
}

.image {
  @apply hidden;

  @media screen and (min-width: theme('screens.lg')) {
    @apply block object-cover w-full h-[430px];
  }
}
