.tooltipContainer {
  @apply p-m z-50;

  border-radius: 10px;
  border: 1px solid var(--outline-selected);
  pointer-events: inherit !important;
}

.tooltipArrow {
  &::before {
    border-bottom-color: var(--outline-selected) !important;
    border-top-color: var(--outline-selected) !important;
  }
}

.closeButton {
  @apply flex h-l w-l;
}
