.container {
  &.lilac {
    background-color: var(--lilac);

    .statValue {
      color: var(--purple);
    }
  }

  &.throwback {
    background-color: var(--throwback);
  }

  &.protocol {
    background-color: var(--protocol);
  }
}

.stat {
  @apply flex flex-col items-center text-center;
}
