@use '@assets/mixins/typography';

.wrapper {
  max-width: 1080px;
}

.eyebrow {
  text-align: center;

  @include typography.text('eyebrow');
}

.title {
  @apply py-2;
  @include typography.text('header-5');

  color: var(--black);
  width: 100%;
}

.separator {
  @apply border-b border-outline-primary w-full;
}

.content {
  @apply px-4 py-2;

  p {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }

  a {
    color: var(--cta);
    font-weight: bold;
  }

  ol {
    list-style: decimal;
  }

  ul {
    list-style: disc;
  }

  h1 {
    @include typography.text('header-1');
  }

  h2 {
    @include typography.text('header-2');
  }

  h3 {
    @include typography.text('header-3');
  }

  h4 {
    @include typography.text('header-4');
  }

  h5 {
    @include typography.text('header-5');

    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
}
