.wrapper {
  @extend %wrapper;
  @apply text-center items-center;

  max-width: 100%;
  width: 80%;

  &WithImage {
    @extend %wrapper;
    @apply text-left items-baseline;
  }
}

%wrapper {
  @apply flex justify-center flex-col py-8 mx-auto px-4 w-full;

  @screen lg {
    @apply ps-24 pe-18;

    min-height: 200px;
  }
}

.headline {
  @apply text-contrasted capitalize;
}

.paragraph {
  @apply w-full gap-y-4 flex flex-col;

  ul {
    @apply ml-l;

    list-style: disc;
  }

  ol {
    @apply ml-l;

    list-style: decimal;
  }

  a {
    @apply text-hover-cta underline;

    &:active,
    &:focus,
    &:hover {
      color: var(--purple);
    }
  }
}

.image {
  @apply h-full object-cover aspect-square md:aspect-auto;

  max-height: 300px;
  width: 100%;

  @screen md {
    max-height: 400px;
  }

  @screen lg {
    max-height: unset;
  }
}

.button {
  min-width: 10rem;

  &Normal {
    @apply mt-xl;

    @screen md {
      @apply mt-xxl;
    }
  }
}

.logo {
  width: 42px;

  @screen md {
    width: 62px;
  }
}
