@use '@assets/mixins/typography';

.subheader {
  @screen md {
    @include typography.text('header-2');
  }
}

.headline {
  text-transform: uppercase;
  color: var(--purple);
}

.copy {
  font-size: 16px;

  @screen lg {
    font-size: 18px;
  }
}

.subheader,
.headline,
.copy {
  @apply lg:max-w-[980px] mx-auto text-center;
}

.wrap {
  @apply grid grid-cols-1 md:grid-cols-3 gap-y-10 gap-x-6 lg:gap-8 xl:gap-16 mt-10 lg:mt-16;
}

.review {
  @apply max-w-[400px] md:max-w-full mx-auto;
}

.reviewIcon {
  @apply flex items-center mx-auto mb-4 lg:mb-5 justify-center;

  width: 50px;
  height: 50px;
  align-items: center;
  padding: 14px;
  background: var(--purple);
  border: 2px solid var(--blue-700);
  box-shadow: 4px 4px 0 var(--blue-700);
  border-radius: 100%;

  @screen lg {
    width: 60px;
    height: 60px;
  }
}

.reviewText {
  font-weight: 400;
  font-size: 17px;
  line-height: 1.2;
  color: var(--blue-700);

  @screen lg {
    font-size: 20px;
  }
}

.author {
  @apply uppercase font-bold mt-2 lg:mt-3;

  font-size: 14px;

  @screen lg {
    font-size: 16px;
  }
}
