.tabsHeader {
  margin-bottom: 40px;
}

.tabContainer {
  width: 168px;
  height: auto;
  min-height: 79px;
  display: flex;
  justify-content: center;

  @media (width <= 1024px) {
    width: 140px;
  }

  @media (width <= 768px) {
    width: 98px;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  @media (width <= 480px) {
    width: 80px;
    gap: 0.1rem;
  }

  @media (width <= 390px) {
    width: 78px;
    gap: 0;
  }
}

.tab {
  @apply p-2;

  width: 100%;
  height: auto;
  border-radius: 24px;

  &:hover {
    background-color: var(--gray-5);
  }

  &.active {
    background-color: #d2d2d2;
  }

  @media (width <= 390px) {
    width: 78px;
  }
}

.icon {
  width: 36px;
  height: 36px;
}

.tabContent {
  width: auto;
  height: 540px;
  border-radius: 32px;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;

  @media (width <= 768px) {
    min-height: 341px;
  }
}

.tabAnimationContainer {
  @apply relative flex flex-col items-center;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tabContentContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 900ms ease;

  &.active {
    z-index: 1;
    opacity: 1;
  }
}

.tabBody {
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}
