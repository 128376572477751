@use 'sass:map';

// note: the sizes and styling diverts away from our typography
// future todo: consolidate this into our typography

$sizes: (
  'SM': clamp(20px, 5vw + 0.5rem, 38px),
  'M': clamp(28px, 5vw + 0.5rem, 50px),
  'L': clamp(32px, 5vw + 0.5rem, 62px),
  'XL': clamp(40px, 5vw + 0.5rem, 90px),
  'XXL': clamp(60px, 5vw + 0.5rem, 130px),
);
$weights: (
  'Normal': 700,
  'Bulky': 900,
);

@mixin text-size($size: 'L', $important: false) {
  font-size: map.get($sizes, $size) #{if($important, '!important', '')};
}

@mixin text-weight($type: 'Normal', $important: false) {
  font-weight: map.get($weights, $type) #{if($important, '!important', '')};
}

/* stylelint-disable selector-class-pattern */
@mixin text-option($important: false) {
  &.SM {
    @include text-size('SM', $important);
  }

  &.M {
    @include text-size('M', $important);
  }

  &.L {
    @include text-size('L', $important);
  }

  &.XL {
    @include text-size('XL', $important);
  }

  &.XXL {
    @include text-size('XXL', $important);
  }

  &.Normal {
    @include text-weight('Normal', $important);
  }

  &.Bulky {
    @include text-weight('Bulky', $important);
  }
}
/* stylelint-enable selector-class-pattern */
