@use '@assets/mixins/typography';

.subheader {
  @apply text-center font-bold;

  color: var(--blue-700);
  font-size: 28px;

  @screen md {
    font-size: 32px;
  }

  @screen lg {
    font-size: 40px;
  }

  & + .productContainer {
    @apply mt-6 md:mt-8 lg:mt-10;
  }
}

.item {
  width: 100%;

  @screen sm {
    flex: 0 0 auto;
    width: calc(50% - 1.25rem);
  }

  @screen md {
    width: calc(33.333% - 1.25rem);
  }

  @screen lg {
    width: calc(20% - 1.25rem);
  }

  a {
    &:hover {
      .name {
        color: var(--purple);
      }

      .image {
        @apply scale-105;
      }
    }
  }
}

.image {
  @apply transition duration-150 ease-linear;

  width: 160px;
}

.name {
  @apply font-bold text-center sm:text-left transition duration-150 ease-linear;

  font-size: 18px;
  margin-bottom: 12px;
  color: var(--black);
}

.description {
  @apply text-center sm:text-left;
  @include typography.text('medium');

  color: var(--gray-50);

  @screen md {
    @include typography.text('base');
  }
}
