@use '@assets/mixins/typography';

.container {
  @apply mx-auto flex flex-col;

  @screen md {
    @apply flex-row border border-blue-600;
  }

  @screen lg {
    max-width: 1080px;
  }
}

.contentBgImage {
  @apply absolute h-full w-full top-0 object-cover;
}

.contentBgOverlay {
  @apply absolute h-full w-full top-0;

  background: var(--black);
  opacity: 0.6;
}

.contentWrapper {
  @apply flex flex-col m-auto gap-y-6 p-4 z-[1];

  @screen md {
    max-width: 420px;
  }

  @screen lg {
    @apply px-7;
  }
}

.cta {
  @apply flex w-fit px-6 py-3 rounded-md;
  @include typography.text('medium');

  line-height: 1;
}

.title,
.ctaWrapper {
  @apply flex text-center justify-center;
}

.iconContainer {
  @apply p-8 flex items-center justify-center grow;
}

.iconWrapper {
  @apply w-full grid grid-cols-1 gap-8;

  @screen md {
    @apply grid-cols-2 w-fit;
  }
}

.iconItem {
  @apply p-4 flex flex-col gap-2 items-center w-full;

  @screen md {
    max-width: 180px;
  }
}

.iconImage {
  width: 30px;
  height: 30px;
}

// alignment
.left {
  @screen md {
    @apply text-left justify-start;
  }
}

.right {
  @screen md {
    @apply text-right justify-end;
  }
}

.center {
  @screen md {
    @apply text-center justify-center;
  }
}

// theme/colors
.lightblue {
  @apply bg-light-blue-400;

  .title {
    @apply text-blue-600;
  }

  .cta {
    @apply bg-blue-600 text-white;
  }
}

.blue {
  @apply bg-blue-600;

  .title {
    @apply text-white;
  }

  .cta {
    @apply bg-blue-600 text-white border border-white;
  }
}

.mintgreen {
  @apply bg-mintgreen-200;

  .title {
    @apply text-blue-600;
  }

  .cta {
    @apply bg-blue-600 text-white;
  }
}

.purple {
  @apply bg-purple;

  .title {
    @apply text-white;
  }

  .cta {
    @apply bg-purple text-white border border-white;
  }
}

.hasBgImage {
  .title {
    @apply text-white;
  }

  .cta {
    @apply bg-purple text-white;
  }
}
