.headline {
  @apply text-cta font-semibold capitalize;
}

.title {
  @apply text-black mb-2;
}

.price {
  @apply mb-4;
}

.imageContent {
  @apply flex;

  min-height: 290px;

  & picture {
    @apply contents;
  }

  & img {
    object-fit: cover;
  }
}

.imageContentColumn {
  @apply flex flex-col h-full items-start justify-center pt-8 lg:pt-0;

  @screen lg {
    @apply py-8;

    padding-left: 80px;
    max-width: 450px;
  }

  @screen xl {
    max-width: 590px;
    padding-left: 100px;
  }
}
