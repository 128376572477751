.header {
  @apply text-center mb-l;

  @screen lg {
    @apply mb-xl text-left;
  }
}

.carouselSection {
  position: relative;
  background-color: var(--white);
  overflow: hidden;
}

.arrowContainer {
  @apply absolute right-0 bottom-2/3 z-50 flex-row justify-between w-full items-center px-2 flex opacity-80;
}

.arrow {
  @apply text-xs md:text-sm border rounded-full flex items-center justify-center;

  width: 44px;
  height: 44px;
  background-color: white !important;
  color: black;
}
