@use '~assets/mixins/contentful-text-option' as option;

.carouselSection {
  position: relative;
  background-color: var(--white);
  overflow: hidden;
}

.swiperMaterial {
  // vars
  --swiper-material-scale: 1;
  --swiper-material-slide-size: 100%;
  --swiper-material-slide-border-radius: 18px;

  height: 440px;
  width: 100%;
  position: relative;

  @screen lg {
    --swiper-material-slide-border-radius: 32px;

    height: 590px;
    width: 125%;
    margin-left: -12.5%;
  }
}

.swiperMaterialWrapper {
  border-radius: var(--swiper-material-slide-border-radius);
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  box-sizing: border-box;
  pointer-events: auto;
}

.swiperMaterialContent {
  position: absolute;
  transform: translate3d(0, 0, 0);
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: var(--swiper-material-slide-size);
  left: calc(50% - var(--swiper-material-slide-size) / 2);
  top: 0;
}

.materialCarouselMedia {
  position: absolute;
  left: 0%;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  border-radius: var(--swiper-material-slide-border-radius);
}

.blackOverlay {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
}

.materialCarouselCopy {
  position: absolute;
  font-size: 24px;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 30px;
  display: flex;
  flex-direction: column;
  font-weight: 500;

  @screen lg {
    padding: 40px;
  }

  @screen xl {
    padding: 60px;
  }

  @screen 2xl {
    padding: 70px;
  }
}

.subHeader {
  color: inherit !important;

  @apply mt-4 mb-0;

  @media screen and (width >= 800px) {
    font-size: 21px;

    @apply mt-6;
  }
}

.slideHeader {
  @include option.text-option(true);

  color: inherit !important;
  line-height: 0.82;
  margin: 0;
}

.materialCarouselCtas {
  display: flex;
  flex-flow: column wrap;
  gap: 16px;
  margin-top: 27px;

  @media screen and (width >= 450px) {
    flex-direction: row;
  }

  @media screen and (width >= 800px) {
    margin-top: 56px;
  }

  a,
  button {
    position: relative;
    overflow: hidden;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: 700;
    border-radius: 50rem;
    backdrop-filter: blur(12px);
    transition: all 0.25s linear;

    &::after {
      content: '';
      position: absolute;
      border-radius: 50rem;
      inset: 0 -1px;
      background-color: var(--purple);
      transform: translateX(-100%);
      opacity: 0;
      visibility: hidden;
      z-index: -1;
      transition: all 0.25s linear;
    }

    &:hover {
      &::after {
        transform: translate(0);
        opacity: 1;
        visibility: visible;
      }
    }

    &.primaryCta {
      color: var(--white);

      &Purple {
        background: rgba(134, 82, 255, 0.8);

        &:hover {
          background: rgba(134, 82, 255, 0.8);
        }
      }

      &Black {
        background: var(--pitch-black);

        &:hover {
          background: var(--pitch-black);
        }
      }

      &White {
        background: rgba(255, 255, 255, 0.6);
        color: var(--black);

        &:hover {
          background: rgba(255, 255, 255, 0.6);
        }
      }
    }

    &.secondaryCta {
      background: rgba(255, 255, 255, 0.6);
      color: var(--black);

      &:hover {
        background: rgba(255, 255, 255, 0.6);
      }
    }
  }
}

.navButtonPrev,
.navButtonNext {
  top: 20px;
  width: 6.5%;
  height: 590px;

  @media screen and (width >= 800px) {
    top: 30px;
  }

  @media screen and (width >= 1080px) {
    width: 19%;
  }
}

.materialPagination {
  // Progress fill var
  --progress: 0; // start 0

  bottom: 0 !important;

  @media screen and (width >= 800px) {
    bottom: 14px !important;
  }

  &Bullet {
    width: 16px !important;
    height: 8px !important;
    border-radius: 50rem !important;
    margin: 0 7px !important;
    transition: 0.3s ease width;
    position: relative;
    overflow: hidden;
    opacity: 1 !important;
    background-color: var(--gray-20) !important;

    .wormLoader {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 0;
      background-color: var(--purple) !important;
      border-radius: 50rem;
      display: none;
    }

    &Active {
      width: 60px !important;
      background-color: var(--gray-20) !important;

      @media screen and (width >= 800px) {
        width: 72px !important;
      }

      .wormLoader {
        width: var(--progress);
        display: block;
      }
    }
  }
}

// Single image case
.banner {
  min-height: 440px;

  @screen lg {
    min-height: 590px;
    border-radius: 32px;
  }

  .materialCarouselCopy {
    padding: 50px 30px;

    @screen lg {
      padding-inline: 40px;
    }

    @screen xl {
      padding: 60px 50px;
    }

    @screen 2xl {
      padding: 70px 60px;
    }
  }
}
