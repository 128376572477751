.headline {
  @apply text-cta text-center;
}

.title {
  @apply text-black text-center mb-5 md:mb-16;
}

.tab {
  @apply font-semibold text-center px-4 py-3 border-0 rounded-none leading-tight normal-case text-black;

  font-family: var(--font-primary) !important;
  font-size: 22px !important;
  min-height: 51.5px;
  min-width: 90px;

  &:hover:not([disabled]) {
    border-width: inherit;
    border-bottom-width: 2px;
  }

  &:focus {
    @apply shadow-none;
  }

  &.selectedTab {
    @apply text-cta #{!important};

    border-bottom-width: 2px;
  }
}

.tabIndicator {
  @apply bg-cta #{!important};
}

.selectRoot {
  @apply pt-4;

  select {
    @apply leading-normal text-black #{!important};

    font-size: 14px !important;
    font-family: var(--font-primary) !important;
  }
}
