.wrapper {
  @extend %wrapper;
  @apply text-center items-center;

  max-width: 100%;
  width: 80%;

  &WithImage {
    @extend %wrapper;
    @apply text-left items-baseline;
  }
}

%wrapper {
  @apply flex justify-center flex-col mx-auto w-full;
}

.headline {
  @apply text-contrasted capitalize;
}

.paragraph {
  @apply w-full;

  ul {
    @apply ml-l;

    list-style: disc;
  }

  ol {
    @apply ml-l;

    list-style: decimal;
  }

  a {
    @apply text-hover-cta underline;

    &:active,
    &:focus,
    &:hover {
      color: var(--purple);
    }
  }
}

.image {
  @apply object-cover;

  max-height: 311px;

  @screen md {
    max-height: 352px;
  }

  @screen lg {
    max-height: 540px;
  }
}

.button {
  min-width: 10rem;

  &Normal {
    @apply mt-xl;

    @screen md {
      @apply mt-xxl;
    }
  }
}

.logo {
  width: 42px;

  @screen md {
    width: 62px;
  }
}
