@use '@assets/mixins/typography';
@use '@assets/mixins/input' as i;

.root {
  @apply relative;
  @include typography.text('text-3');
}

.label {
  @include i.label;

  z-index: 1;
}

.focusedLabel {
  @include i.label(focused);
}

.autofillFocusedLabels {
  .label {
    @include i.label(focused);
  }
}

.withoutLabel {
  @include i.input(without-label);
}

.input {
  @include i.input;
}

.focusedInput:not(:disabled) {
  @include i.input(focused);
}

.errorInput:not(:disabled) {
  @include i.input(error);
}

.helperText {
  @include i.label(helper);
}

.isError {
  @include i.label(error);
}

.isDisabled {
  @include i.label(disabled);
}

.select {
  @apply appearance-none cursor-pointer rounded-none;

  overflow-x: hidden;
  text-overflow: ellipsis;
  padding-right: 30px;
}

.caret {
  @apply absolute pointer-events-none;

  top: 40%;
  right: 10px;
}
