@use '@assets/mixins/typography';

.seatbeltWrapper {
  @apply flex-wrap justify-center;
}

.horizontalScroll {
  @apply flex-nowrap justify-start;

  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  scrollbar-width: none;
  width: fit-content;
  max-width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
}

.item {
  flex: 0 0 auto;
  width: calc(120px + 1rem);
  box-shadow: 0 4px 8px rgba(36, 0, 71, 0.1);
  transition:
    background-color 0.3s ease,
    transform 0.3s ease,
    box-shadow 0.3s ease;
  scroll-snap-align: center;

  @screen md {
    width: calc(140px + 1rem);
  }

  @media (hover: hover) {
    &:hover {
      background-color: var(--lilac-30);
      transform: translateY(-8px);
      box-shadow: 0 8px 16px rgba(36, 0, 71, 0.2);
    }
  }
}

p.itemTitle {
  @include typography.text('base');

  @screen md {
    @include typography.text('medium');
  }
}

.eyebrow {
  color: var(--eggplant);
}
