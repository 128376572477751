.plan {
  @apply w-full border border-outline-primary rounded-3xl p-8 flex flex-col items-center;
}

.price {
  @apply mb-xl flex-grow text-xl lg:text-[24px];

  & > strong {
    color: var(--text-primary) !important;
  }
}

.button {
  min-width: 13rem;
  max-width: 100%;
}

.comparison {
  @apply flex flex-col items-center space-y-1 lg:items-start lg:space-y-0 lg:flex-row lg:space-x-5;

  .comparisonLabel {
    @apply w-full flex flex-row;
  }

  .comparisonContent {
    @apply font-bold text-primary lg:flex-shrink-0;
  }
}
