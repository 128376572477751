.root {
  padding-top: 100%;

  .contentRoot {
    @apply px-3 py-3 md:pt-5 flex flex-col h-full justify-center items-center;
  }

  .name {
    @apply text-secondary;
  }
}
