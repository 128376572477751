@use '@assets/mixins/darken' as dark;

.root {
  @apply flex items-center justify-center w-full relative;

  min-height: 400px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: var(--black);

  &.top .wrapper {
    top: 23px;
  }

  &.bottom .wrapper {
    bottom: 23px;
  }

  @screen md {
    &.mdTop {
      @apply items-start;

      .wrapper {
        top: 70px;
      }
    }

    &.mdBottom {
      @apply items-end;

      .wrapper {
        bottom: 70px;
      }
    }
  }

  @screen lg {
    aspect-ratio: 12/5;
  }
}

.bgImage {
  @apply w-full h-full;

  @screen md {
    @apply absolute inset-0;
  }

  min-height: 400px;
  z-index: 0;
  object-fit: cover;
}

.pictureClass {
  @media screen and (width <= 768px) {
    width: 100%;
  }
}

.darken {
  @include dark.darken;
}

.wrapper {
  @apply text-center;

  z-index: 1;
  width: 90%;
  max-width: theme('screens.xl');
  position: absolute;

  &Right {
    @apply text-right;
  }

  &Left {
    @apply text-left;
  }

  @screen md {
    position: inherit;
    width: 80%;

    &MdLeft,
    &MdRight {
      width: 40%;
    }

    &MdLeft {
      @apply text-left;

      margin-right: 40%;
    }

    &MdRight {
      @apply text-right;

      margin-left: 40%;
    }
  }
}

.button {
  @apply mt-6;

  @screen md {
    @apply mt-8;
  }
}
